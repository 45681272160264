// import React from "react";
// import { FaChevronDown } from "react-icons/fa";
// import logo from "../../assets/logo main.svg";

// export default function NavbarComp() {
//   return (
//     <div>
//       <nav className="navbar navbar-expand-lg  ">
//         <div className="container py-3 col-md-8 mx-auto d-md-flex bar">
//           <a className="navbar-brand" href="#">
//             <img src={logo} alt="Logo" width="120" />
//           </a>
//           <button
//             className="navbar-toggler"
//             style={{ color: "green" }}
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarSupportedContent"
//             aria-controls="navbarSupportedContent"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <span
//               className="navbar-toggler-icon"
//               style={{ color: "green" }}
//             ></span>
//           </button>
//           <div
//             className="collapse navbar-collapse justify-content-center"
//             id="navbarSupportedContent"
//           >
//             <ul className="navbar-nav mb-2 mb-lg-0 navlist">
//               {/* company */}
//               <li className="nav-item dropdown">
//                 <a
//                   className="nav-link navlist"
//                   href="#"
//                   role="button"
//                   data-bs-toggle="dropdown"
//                   aria-expanded="false"
//                 >
//                   Company
//                   <FaChevronDown className="ms-2" />
//                 </a>
//                 {/* <ul className="dropdown-menu">
//                   <li>
//                     <a className="dropdown-item" href="#">
//                       Action
//                     </a>
//                   </li>
//                   <li>
//                     <a className="dropdown-item" href="#">
//                       Another action
//                     </a>
//                   </li>
//                   <li>
//                     <hr className="dropdown-divider" />
//                   </li>
//                   <li>
//                     <a className="dropdown-item" href="#">
//                       Something else here
//                     </a>
//                   </li>
//                 </ul> */}
//               </li>
//               {/* product */}
//               <li className="nav-item dropdown">
//                 <a
//                   className="nav-link "
//                   href="#"
//                   role="button"
//                   data-bs-toggle="dropdown"
//                   aria-expanded="false"
//                 >
//                   Product
//                   <FaChevronDown className="ms-2" />
//                 </a>
//                 {/* <ul className="dropdown-menu">
//                   <li>
//                     <a className="dropdown-item" href="#">
//                       Action
//                     </a>
//                   </li>
//                   <li>
//                     <a className="dropdown-item" href="#">
//                       Another action
//                     </a>
//                   </li>
//                   <li>
//                     <hr className="dropdown-divider" />
//                   </li>
//                   <li>
//                     <a className="dropdown-item" href="#">
//                       Something else here
//                     </a>
//                   </li>
//                 </ul> */}
//               </li>

//               {/* contact */}
//               <li className="nav-item dropdown">
//                 <a
//                   className="nav-link "
//                   href="#"
//                   role="button"
//                   data-bs-toggle="dropdown"
//                   aria-expanded="false"
//                 >
//                   Contact
//                   <FaChevronDown className="ms-2" />
//                 </a>
//                 {/* <ul className="dropdown-menu">
//                   <li>
//                     <a className="dropdown-item" href="#">
//                       Action
//                     </a>
//                   </li>

//                   <li>
//                     <hr className="dropdown-divider" />
//                   </li>

//                 </ul> */}
//               </li>

//               <li className="nav-item d-lg-none btn ">
//                 <a className="nav-link btn-black btn-green-mobile" href="#">
//                   Sign In
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div className="d-none d-lg-flex">
//             <button className="btn btn-green px-3">Sign In</button>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// }

import React from "react";
import { FaChevronDown } from "react-icons/fa";
import logo from "../../assets/logo main.svg";
import menu from "../../assets/Hamburger.png";
import { Link } from "react-router-dom";

export default function NavbarComp() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg px-4 p-md-3">
        <div className="container py-3 col-md-8 mx-auto d-md-flex bar">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo" width="120" />
          </a>
          <img
            src={menu}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            alt="menu"
            className="d-lg-none"
          />
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 navlist py-2">
              {/* company */}
              <li className="nav-item dropdown py-2">
                <a
                  className="nav-link navlist"
                  href="/about"
                  role="button"
                  aria-expanded="false"
                >
                  About
                  {/* <FaChevronDown className="ms-2" /> */}
                </a>
                {/* <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Blog
                    </a>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item dropdown py-2">
                <a
                  className="nav-link navlist"
                  href="/contact"
                  role="button"
                  aria-expanded="false"
                >
                  Contact
                  {/* <FaChevronDown className="ms-2" /> */}
                </a>
                {/* <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Blog
                    </a>
                  </li>
                </ul> */}
              </li>
              {/* product */}
              {/* <li className="nav-item dropdown py-2">
                <a
                  className="nav-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                 
                  <FaChevronDown className="ms-2" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Features
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Reviews
                    </a>
                  </li>
                </ul>
              </li> */}
              {/* contact */}

              <li className="nav-item d-lg-none btn">
                <a
                  className="nav-link btn-green-mobile atag"
                  href="http://app.useclars.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sign In
                </a>
              </li>
            </ul>
          </div>
          <div className="d-none d-lg-flex">
            <a
              className="btn btn-green px-3 atag"
              href="http://app.useclars.com/"
              target="_blank"
              rel="noreferrer"
            >
              Sign In
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}
