import React from "react";
import heroImg from "../../assets/Illustration.png";
import empowerImg from "../../assets/empower-card-img.png";
import navigateImg from "../../assets/navigate-card-img.png";
import bank from "../../assets/bank.png";
import {
  BlackArrowCircleRight,
  DollarCircleIconBlack,
  EyeIconBlack,
  MessageTextIconBlack,
  SliderIconOne,
  SliderIconThree,
  SliderIconTwo,
  WhiteArrowCircleRight,
} from "../../assets/svgs";
import NavbarComp from "../../components/navbar";
import FooterComp from "../../components/footer";
import FAQSection from "./FAQ";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2.0,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Card = ({
  bgColor,
  imgSrc,
  title,
  description,
  btnText,
  btnLink,
  btnIcon,
  style = {},
}) => (
  <div className="col-md-6">
    <div
      className={`card rounded-4 text-${
        bgColor === "#4161DF" ? "white" : "dark"
      }`}
      style={{ backgroundColor: bgColor }}
    >
      <div className="card-body">
        <img src={imgSrc} alt={title} className="w-100 mb-3" />
        <h3>{title}</h3>
        <p>{description}</p>
        <a
          className={`btn btn-${
            bgColor === "#4161DF" ? "white-outline mt-5" : "black-outline"
          } atag`}
          href={btnLink}
          target="_blank"
          rel="noreferrer"
        >
          {btnText}
          <span className="ms-3">{btnIcon}</span>
        </a>
      </div>
    </div>
  </div>
);

const SliderCard = ({ icon, title, description }) => (
  <div
    className="col slidercon"
    style={{ paddingLeft: "10px", paddingRight: "10px", maxWidth: "570px" }}
  >
    <div className="card bg-light border-0 rounded-4">
      <div className="card-body">
        <span>{icon}</span>
        <h4 className="mt-3">{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

const Feature = ({ icon, title, description }) => (
  <div className="col-md-4 text-start px-md-4">
    <span>{icon}</span>
    <h5 className="mt-3">{title}</h5>
    <p>{description}</p>
  </div>
);

export default function HomePage() {
  return (
    <div>
      <NavbarComp />

      <section className="hero-area">
        <div className="container">
          <div className="col-md-8 mx-auto text-center pt-2 content">
            <h1 className="py-2">Automate Your Lending Process With CLARS</h1>
          </div>
          <div className="col-md-7 mx-auto text-center pt-2 content">
            <h5>
              CLARS elevates lending operations through technology designed to
              automate and manage collections; creating sustainable financial
              growth and efficiency for loan providers.
            </h5>
            <br />
            {/* <a
              className="btn btn-black bg-green px-3 py-3 mt-3 atag"
              href="http://app.useclars.com/"
              target="_blank"
              rel="noreferrer"
            >
              Check Credit Score
            </a> */}
          </div>
          <br />
          <img src={heroImg} alt="Hero" className="w-100" />
        </div>
      </section>

      <section className="section3 py-5">
        <div className="container">
          <div className="col-md-8 mx-auto">
            <div className="row">
              <Card
                bgColor="#4161DF"
                imgSrc={empowerImg}
                title="Account Integration"
                description="Clars extends a safety net by integrating all associated accounts guaranteeing payment security."
                btnText="Get Started"
                btnLink="http://app.useclars.com/"
                btnIcon={WhiteArrowCircleRight}
              />

              <Card
                bgColor="#B0E8C7"
                imgSrc={navigateImg}
                title="Account Automation"
                description="Clars automates the end-to-end loan management cycle, from collection to repayment; creating sustainable financial growth and efficiency for loan providers."
                btnText="Sign Up"
                btnLink="http://app.useclars.com/"
                btnIcon={BlackArrowCircleRight}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="col-md-8 mx-auto">
            <div className="row">
              <div className="col-md-6">
                <h1>Why CLARS</h1>
              </div>
              <div className="col-md-6">
                <p>
                  CLARS streamlines loan collections by integrating data for
                  automatic payments, while optimizing the entire lending
                  process ensuring efficiency and business growth for loan
                  providers
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="col-md-10 ms-auto">
            <div className="py-5">
              <Slider {...settings}>
                <SliderCard
                  icon={SliderIconOne}
                  title="Risk Scoring"
                  description="CLARS integrates data from Credit Bureaus, Telecom records, and Bank accounts to accurately assess borrower risk & create a full financial profile that facilitates automatic debits for timely payment collection."
                />
                <SliderCard
                  icon={SliderIconTwo}
                  title="Loan Collection"
                  description="Loan Providers can gain full visibility into all digital bank accounts linked to a BVN for both debtors and guarantors to process automations & ensure timely collections."
                />
                <SliderCard
                  icon={SliderIconThree}
                  title="Loan Approval"
                  description="Loan providers are able to automate the loan approval process, streamlining the end-to-end process from pre-qualification to final approval."
                />
                <SliderCard
                  icon={SliderIconThree}
                  title="Cash Disbursement Management (Beta)"
                  description="CLARS eliminates human error in disbursements, ensuring they are handled with greater efficiency and accuracy."
                />
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light py-5 clarsUpdate">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h2>
                Clars Provides a Robust and Reliable Method for Automatic
                Payment Collections.
              </h2>
            </div>
            <div className="row g-4 justify-content-center px-md-5">
              <Feature
                icon={DollarCircleIconBlack}
                title="Timely Loan Reclaims"
                description="Facilitate automatic debits from any of the borrower’s linked bank accounts, and receive your dues promptly and efficiently."
              />
              <Feature
                icon={MessageTextIconBlack}
                title="Informed Lending Decisions"
                description="Access a borrower's comprehensive financial profile and consolidated view of a borrower’s financial standing."
              />
              <Feature
                icon={EyeIconBlack}
                title="Credit Visibility"
                description="Ascertain a borrower's credit behaviour and expected risk level."
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-green py-5 code-int-area">
        <div className="container">
          <div className="col-md-11 mx-auto text-white">
            <div className="row justify-content-between">
              <div className="col-md-6 py-3 px-md-5 pe-md-5">
                <h2>Enjoy Hassle-Free Loan Operations when you Use CLARS.</h2>
                <a
                  href="http://app.useclars.com/"
                  target="_blank"
                  className="btn btn-white-outline atag mt-3"
                >
                  Get Started
                  <span className="ms-3">{WhiteArrowCircleRight}</span>
                </a>
              </div>

              <div className="col-md-4 ps-md-5">
                <img src={bank} alt="Bank" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <FAQSection />
      <FooterComp />
    </div>
  );
}
