import React from "react";
import heroImg from "../assets/Illustration.png";
import empowerImg from "../assets/empower-card-img.png";
import navigateImg from "../assets/navigate-card-img.png";
import NavbarComp from "../components/navbar";
import { WhiteArrowCircleRight } from "../assets/svgs";
import coin from "../assets/coin (2).png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FooterComp from "../components/footer";
import prembly from "../assets/Prembly-logo 1.png";
import vfd from "../assets/VFD-Group-Logo-PNG.png";
import intelytic from "../assets/logo_dark.png";

export default function About() {
  return (
    <div>
      <NavbarComp />
      <section className="hero-area">
        <div className="container">
          <div className="col-md-8 mx-auto text-center pt-2 content">
            <h1 className="py-2">
              Powering a Sustainable Lending Ecosystem through Technology!{" "}
            </h1>
            <div className="col-md-9 mx-auto lh-3">
              {/* <h5>
                At CLARS, we are committed to transforming the lending industry
                through technology and responsible automation, ensuring
                sustainable financial growth and efficiency for loan providers.
              </h5> */}
              {/* <a
                className="btn btn-black bg-green px-3 py-3 mt-3 atag"
                href="http://app.useclars.com/"
                target="_blank"
                rel="noreferrer"
              >
                Get Started
              </a> */}
            </div>
          </div>
        </div>
      </section>
      <br />

      <section className=" py-5 ">
        <div className="container">
          <div className="col-md-10 mx-auto text-white about-text">
            <div className="row">
              <div className="col-md-7 order-2 order-md-1 py-3 px-md-5 code-about-area">
                <h2 className="about">
                  At CLARS, we are <br />
                  driven by a commitment <br />
                  to transform the lending industry through responsible
                  practices.
                </h2>

                <p>
                  Our story is rooted in the belief that lending is a
                  responsible act that should foster a thriving financial
                  ecosystem.
                </p>
                <p>
                  With CLARS, we've carefully considered both the lender’s and
                  borrower’s journey to create a robust solution that automates
                  and manages collections.
                </p>
                <p>
                  {" "}
                  This approach not only promotes responsible lending but also
                  upholds ethical standards, ensuring sustainable financial
                  growth and efficiency for loan providers. By embracing this
                  vision, we aim to restore trust and create a healthier, more
                  resilient lending industry.
                </p>

                <br />
                <a
                  href="http://app.useclars.com/"
                  target="_blank"
                  className="btn btn-white-outline atag btn-green p-3"
                >
                  Get Started
                  <span className="ms-3">{WhiteArrowCircleRight}</span>
                </a>
              </div>
              <div className="col-md-5 order-1 order-md-2">
                <div style={{ marginTop: "-40px" }} className="text-center">
                  <br />
                  <br />
                  <img
                    src={coin}
                    alt=""
                    className="img-fluid w-85 w-md-100"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className=" text-light d-flex justify-content-between aboutp  py-4">
        <div className="row w-100">
          <div className="col-6 col-md-3 px-5 ">
            <h2>41K+</h2>
            <p>Customers</p>
          </div>
          <div className="col-6 col-md-3 px-5 ">
            <h2>2022</h2>
            <p>Founded</p>
          </div>
          <div className="col-6 col-md-3 px-5 ">
            <h2>N2bn</h2>
            <p>Processed</p>
          </div>
          <div className="col-6 col-md-3 px-2 ">
            <h2>4.8</h2>
            <p>Rating</p>
          </div>
        </div>
      </section> */}

      <div className="mt-3bg-light ">
        <br />
        <h2 className="text-center mt-2">Powered by </h2>
        <div className="d-flex justify-content-around align-items-center mt-4 mb-4">
          <a
            href="https://prembly.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={prembly} className="img-fluid img-size" alt="Prembly" />
          </a>
          <a
            href="https://vfdgroup.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-3"
          >
            <img src={vfd} className="img-fluid img-size" alt="VFD" />
          </a>
          <a
            href="https://intelyticslimited.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={intelytic}
              className="img-fluid img-size"
              alt="Intelytics"
            />
          </a>
        </div>
      </div>

      <FooterComp />
    </div>
  );
}
